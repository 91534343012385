/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 13:15:47
 * @LastEditTime: 2020-04-19 12:15:43
 */
import {
    // get,
    post
} from "./api";
//培训课程名称列表
export const trainingName = (params) => post('/v1/education/training/name',{ request: params });
//教育培训列表
export const trainingList = (params) => post('/v1/education/training/list',{ request: params });
//新增培训计划
export const insertProgramme = (params) => post('/v1/education/insert/programme',{ request: params });
//编辑培训计划
export const updateProgramme = (params) => post('/v1/education/update/programme',{ request: params });
//培训详情
export const details = (params) => post('/v1/education/details',{ request: params });
//教育培训抄送信息
export const information = (params) => post('/v1/education/information',{ request: params });
//培训报名列表
export const signUpList = (params) => post('/v1/education/signUp/list',{ request: params });
//报名列表详情
export const signUpDetails = (params) => post('/v1/education/signUp/details',{ request: params });
//培训列表导入
export const signUpImport = (params) => post('/v1/education/signUp/import',{ request: params });
//报名培训编辑
export const signUpdate = (params) => post('/v1/education/signUp/update',{ request: params });
//公布考核结果
export const results = (params) => post('/v1/education/signUp/results',{ request: params });
//员工端
//员工教育培训列表
export const staffTrainingList = (params) => post('/v1/education/staffTraining/list',{ request: params });
//员工报名培训详情
export const staffTrainingDetails = (params) => post('/v1/education/staffSignUp/details',{ request: params });
//员工报名
export const signUp = (params) => post('/v1/education/staff/signUp',{ request: params });
<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 14:05:09
 * @LastEditTime: 2020-04-30 16:10:24
 -->
<template>
	<div class="app-container trainDetail" v-loading="loading">
		<header>
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ _returnTitle() }}</div>
			</div>
		</header>

		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<section class="sections-container">
				<div class="section-wrap">
					<h3 class="title-icon">基本信息</h3>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="trainingCourse" label="培训课程">
								<el-input
									:disabled="formType != 1"
									class="moryeWidth"
									maxlength="15"
									v-model="form.trainingCourse"
									placeholder="输入培训课程名"
									clearable
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="trainingType" label="培训类型">
								<el-select
									:disabled="formType != 1"
									class="moryeWidth"
									placeholder="请选择培训类型"
									clearable
									v-model="form.trainingType"
								>
									<el-option value="1" label="内部培训"></el-option>
									<el-option value="2" label="部门内训"></el-option>
									<el-option value="3" label="外部培训"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="trainingInstructors" label="培训讲师">
								<el-input
									maxlength="15"
									:disabled="formType != 1"
									class="moryeWidth"
									v-model="form.trainingInstructors"
									placeholder="输入培训讲师名"
									clearable
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="trainingForm" label="培训形式">
								<el-input
									maxlength="15"
									:disabled="formType != 1"
									class="moryeWidth"
									clearable
									v-model="form.trainingForm"
									placeholder="输入培训形式"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="培训开始时间" prop="trainingStartTime">
								<el-date-picker
									:disabled="formType != 1"
									clearable
									class="moryeWidth"
									v-model="form.trainingStartTime"
									type="datetime"
									default-time="[09:00:00]"
									value-format="yyyy-MM-dd HH:mm"
									format="yyyy-MM-dd HH:mm"
									placeholder="选择日期时间"
									:picker-options="pickerOptionsStart"
									@change="_changeStartTime"
								></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="培训结束时间" prop="trainingEndTime">
								<el-date-picker
									:disabled="formType != 1"
									clearable
									class="moryeWidth"
									v-model="form.trainingEndTime"
									type="datetime"
									default-time="[09:00:00]"
									value-format="yyyy-MM-dd HH:mm"
									format="yyyy-MM-dd HH:mm"
									placeholder="选择日期时间"
									:picker-options="pickerOptionsEnd"
									@change="_changeEndTime"
								></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="trainingPlace" label="培训地点">
								<el-input
									maxlength="30"
									show-word-limit
									:disabled="formType != 1"
									class="moryeWidth"
									clearable
									v-model="form.trainingPlace"
									placeholder="输入培训地点"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="trainingTime" label="培训时长">
								<el-input
									maxlength="15"
									:disabled="formType != 1"
									class="moryeWidth"
									clearable
									v-model="form.trainingTime"
									placeholder="输入培训时长"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="undertakingDepartment" label="牵头承办部门">
								<treeselect
									v-if="formType == 1"
									:sizes="'middle'"
									:disable="formType != 1"
									class="moryeWidth"
									:props="depProps"
									:options="deps"
									:value="String(form.undertakingDepartment)"
									:clearable="true"
									:accordion="true"
									:level="String(form.undertakingDepartment)"
									@getValue="_getValue($event)"
								/>
								<el-input disabled class="moryeWidth" :value="form.orgName" v-else></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="assistUndertakingDepartment" label="协助承办部门">
								<treeselect
									v-if="formType == 1"
									:sizes="'middle'"
									:disable="formType != 1"
									class="moryeWidth"
									:props="depProps"
									:options="deps"
									:value="String(form.assistUndertakingDepartment)"
									:clearable="true"
									:accordion="true"
									:level="String(form.assistUndertakingDepartment)"
									@getValue="_getValue2($event)"
								/>
								<el-input disabled class="moryeWidth" :value="form.orgName2" v-else></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="培训内容" prop="trainingContent">
								<el-input
									:disabled="formType != 1"
									type="textarea"
									autosize
									style="width: 83.8%"
									show-word-limit
									maxlength="1000"
									v-model="form.trainingContent"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" v-if="formType != 3">
							<el-form-item label="培训对象">
								<div>
									<span v-show="orgList.length > 0">部门：</span>
									<el-tag
										style="margin-right: 10px"
										v-for="(tag, index) in orgList"
										:key="tag.id"
										:closable="formType == 1"
										@close="handleOrgClose(tag, index)"
										type="primary"
										>{{ tag.orgName || tag.departmentName }}</el-tag
									>
								</div>
								<div>
									<span v-show="userList.length > 0">员工：</span>
									<el-tag
										style="margin-right: 10px"
										v-for="(tag, tagiIndex) in userList"
										:key="tagiIndex"
										:closable="formType == 1"
										@close="handleUserClose(tag, tagiIndex)"
										type="primary"
										>{{ tag.name || tag.staffName }}</el-tag
									>
								</div>
								<div v-if="roleList.length > 0">
									<span>角色：</span>
									<el-tag
										style="margin-right: 10px"
										v-for="(tag, tagiIndex) in roleList"
										:key="tagiIndex"
										:closable="formType == 1"
										@close="handleRoleClose(tag, tagiIndex)"
										type="primary"
										>{{ tag.name }}</el-tag
									>
								</div>
								<depts
									v-if="formType == 1"
									:disabled="formType == 1"
									@updateTag="updateTag"
									:showStaff="true"
									:checkStrictly="true"
									:setChecks="orgIds"
									:userList="userList"
									@updateStaffTag="updateStaffTag"
								></depts>
								<p class="warningWord" v-if="formType != 1 && orgList.length <= 0 && userList.length <= 0">全员工</p>
								<p class="warningWord" v-if="formType == 1">温馨提示：若部门或员工不选，则默认所有部门和员工</p>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="培训资料">
								<el-upload
									v-if="formType == 1"
									ref="upload"
									style="width: 40%"
									class="upload-demo"
									action="#"
									element-loading-text="正在上传......"
									show-file-list
									multiple
									:limit="5"
									v-loading="contrachLoading"
									:file-list="fileList"
									:on-exceed="handleExceed"
									:on-change="handleChange"
									:on-remove="handleRemove"
									:auto-upload="false"
									accept=".JPG, .JPEG, .DOC, .DOCX, .XLS, .XLSX, .PDF, .PPT, .ZIP, .RAR"
								>
									<el-button size="small" type="primary">点击上传</el-button>
									<div slot="tip" class="el-upload__tip" style="line-height: 20px">
										文件格式为JPG/JPEG/DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在5个之内
									</div>
								</el-upload>
								<div v-else>
									<div
										class="docContainer"
										style="width: 100%"
										v-for="(file, index) in makeFileList(form.trainingFile)"
										:key="index"
									>
										<div class="docContent" style="transform: translateY(6px)">
											<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
											<div style="width: auto; height: 55px; margin-left: 5px">
												<!-- <el-tooltip :content="file.name"
                                        placement="top"> -->
												<span class="fileName">{{ file.name }}</span>
												<!-- </el-tooltip> -->
												<div style="text-align: left; transform: translateY(-10px)">
													<el-button
														v-show="isImageFileName(file.url)"
														type="text"
														style="margin-right: 5px"
														size="small"
														class="media-print__hidden"
														@click="picPreview(file.url)"
														>预览</el-button
													>
													<a
														v-show="!isImageFileName(file.url)"
														class="media-print__hidden"
														style="color: #409eff; margin-right: 5px"
														target="_blank"
														:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
														>预览</a
													>
													<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)"
														>下载</el-button
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="备注" prop="remark">
								<el-input
									:disabled="formType != 1"
									type="textarea"
									autosize
									style="width: 83.8%"
									show-word-limit
									maxlength="1000"
									v-model="form.remark"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="培训发布时间" prop="trainingPublishTime">
								<el-date-picker
									:disabled="formType != 1 || disabled"
									clearable
									class="moryeWidth"
									:picker-options="pickerOptionsStart"
									v-model="form.trainingPublishTime"
									type="datetime"
									default-time="[09:00:00]"
									value-format="yyyy-MM-dd HH:mm"
									format="yyyy-MM-dd HH:mm"
									placeholder="选择日期时间"
								></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<el-row style="text-align: center" v-if="formType == 1 || (formType == 3 && !form.accessScore && !$route.query.time)">
					<!--<el-button @click="$router.back()">返回</el-button>!-->
					<el-button type="primary" v-if="formType == 1" @click="_save">提交</el-button>
					<el-button type="primary" v-show="show" v-else @click="_signUp">报名</el-button>
				</el-row>
			</section>
			<section class="sections-container" v-if="(formType == 3 && form.accessScore) || ($route.query.cc && form.accessScore)">
				<div class="section-wrap">
					<h3 class="title-icon">考核信息</h3>
					<el-row>
						<el-col :span="12">
							<el-form-item label="考核结果" prop="accessScore">
								<el-input
									:disabled="formType != 1"
									class="moryeWidth"
									maxlength="20"
									v-model="form.accessScore"
									placeholder="输入考核结果"
									clearable
								/>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="考核评价" prop="accessEvaluate">
								<el-input
									:disabled="formType != 1"
									type="textarea"
									rows="4"
									style="width: 84%"
									show-word-limit
									maxlength="1000"
									v-model="form.accessEvaluate"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</section>
		</el-form>
		<el-dialog :visible.sync="dialogVisible2">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import treeselect from '@/components/treeSelect/treeSelect';
import depts from '@/components/departs';
import { isImageFileName } from '@/utils/util.js';
import { details, insertProgramme, updateProgramme, signUp, staffTrainingDetails } from '@/api/trainMange.js';
export default {
	components: { depts, treeselect },
	data() {
		return {
			id: this.$route.query.id,
			trainingCourseId: this.$route.query.trainingCourseId,
			copy: this.$route.query.copy,
			formType: this.$route.query.type,
			disabled: false,
			show: false,
			orgIds: [],
			orgList: [],
			userList: [],
			roleList: [],
			loading: false,
			contrachLoading: false,
			fileList: [],
			uploadVal: [],
			deps: this.$store.state.setData.depsList,
			dialogImageUrl: '',
			dialogVisible2: false,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			form: {
				trainingCourse: '',
				trainingType: '',
				trainingInstructors: '',
				trainingForm: '',
				trainingStartTime: '',
				trainingEndTime: '',
				trainingPlace: '',
				trainingTime: '',
				undertakingDepartment: '',
				assistUndertakingDepartment: '',
				trainingContent: '',
				remark: '',
				trainingPublishTime: '',
				trainingFile: '',
				assessRangeTypeListDepartmentNameId: [],
				assessRangeTypeListStaffId: [],
				orgName: '',
				orgName2: '',
				accessScore: '',
				accessEvaluate: ''
			},
			rules: {
				trainingCourse: [{ required: true, message: '请输入培训课程名', trigger: 'blur' }],
				trainingInstructors: [{ required: true, message: '请输入培训讲师名', trigger: 'blur' }],
				trainingForm: [{ required: true, message: '请输入培训形式', trigger: 'blur' }],
				trainingStartTime: [{ required: true, message: '请选择培训开始时间', trigger: 'change' }],
				// trainingEndTime: [
				//   { required: true, message: "请选择培训结束时间", trigger: "change" }
				// ],
				trainingPlace: [{ required: true, message: '请输入培训地点', trigger: 'blur' }],
				undertakingDepartment: [{ required: true, message: '请选择牵头承办部门', trigger: 'change' }],
				trainingContent: [{ required: true, message: '请输入培训内容', trigger: 'change' }]
			},
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			pickerOptionsEnd: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	methods: {
		_getValue(eve) {
			this.form.undertakingDepartment = eve ? eve.id : '';
		},
		_getValue2(eve) {
			this.form.assistUndertakingDepartment = eve ? eve.id : '';
		},
		_getDetail(obj) {
			details(obj || { trainingCourseId: this.id }).then((res) => {
				const _res = JSON.parse(JSON.stringify(res));
				const _undertakingDepartmentId = _res.undertakingDepartmentId;
				const _uassistUndertakingDepartmentId = _res.assistUndertakingDepartmentId;

				for (const key in _res) {
					if (this.form.hasOwnProperty(key)) {
						this.form[key] = _res[key];
					}
				}
				if (new Date(this.form.trainingStartTime).getTime() > new Date().getTime()) {
					this.disabled = true;
				}
				if (!this.copy) {
					_res.assessRangeTypeDepar &&
						_res.assessRangeTypeDepar.forEach((item) => {
							this.orgList.push(item);
							this.orgIds.push(item.departmentNameId);
						});
					_res.assessRangeTypeStaff &&
						_res.assessRangeTypeStaff.forEach((item) => {
							this.userList.push(item);
						});
				} else {
					this.form.trainingStartTime = '';
					this.form.trainingEndTime = '';
					this.form.trainingTime = '';
				}
				this.form.undertakingDepartment = String(_undertakingDepartmentId);
				this.form.assistUndertakingDepartment = String(_uassistUndertakingDepartmentId);
				this.form.orgName = _res.undertakingDepartment;
				this.form.orgName2 = _res.assistUndertakingDepartment;
				this.fileList = this.uploadVal = JSON.parse(res.trainingFile);
				this.$forceUpdate();
			});
		},
		_getStaffDetail() {
			staffTrainingDetails({ id: this.id, trainingCourseId: this.trainingCourseId, readStatus: 1 }).then((res) => {
				const _res = JSON.parse(JSON.stringify(res));
				const _undertakingDepartmentId = _res.undertakingDepartmentId;
				const _uassistUndertakingDepartmentId = _res.assistUndertakingDepartmentId;

				for (const key in _res) {
					if (this.form.hasOwnProperty(key)) {
						this.form[key] = _res[key];
					}
				}
				if (new Date(this.form.trainingStartTime).getTime() > new Date().getTime()) {
					this.show = true;
				}
				this.form.undertakingDepartment = String(_undertakingDepartmentId);
				this.form.assistUndertakingDepartment = String(_uassistUndertakingDepartmentId);
				this.form.orgName = _res.undertakingDepartment;
				this.form.orgName2 = _res.assistUndertakingDepartment;
				this.fileList = this.uploadVal = JSON.parse(res.trainingFile);
				this.$forceUpdate();
			});
		},
		_save() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					const _obj = { ...this.form };

					_obj.assessRangeTypeListDepartmentNameId = this.orgIds;
					_obj.assessRangeTypeListStaffId = [];
					this.userList.forEach((item) => _obj.assessRangeTypeListStaffId.push(item.staffId));
					_obj.trainingFile = JSON.stringify(this.uploadVal);
					this.loading = true;
					if (this.copy || (!this.id && !this.copy)) {
						this.loading = false;
						insertProgramme(_obj).then((res) => {
							if (res.code == 0) {
								this.$message.success('新增计划成功');
								this.$router.back();
							}
						});
					} else {
						_obj.trainingCourseId = this.id;
						delete _obj.assessRangeTypeStaff;
						delete _obj.assessRangeTypeDepar;
						updateProgramme(_obj).then((res) => {
							this.loading = false;
							if (res.code == 0) {
								this.$message.success('编辑计划成功');
								this.$router.back();
							}
						});
					}
				}
			});
		},
		_signUp() {
			this.$confirm(`您确认要报名${this.form.trainingCourse}培训课程吗？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					signUp({ id: this.id, trainingCourseId: this.trainingCourseId }).then((res) => {
						if (res.code == 0) {
							this.$message.success('报名成功');
							this.$router.back();
						}
					});
				})
				.catch(() => {});
		},
		handleRoleClose(node, index) {
			this.roleList.splice(index, 1);
		},
		handleOrgClose(tag, index) {
			this.orgList.splice(this.orgList.indexOf(tag), 1);
			this.orgIds.splice(index, 1);
		},
		handleUserClose(tag) {
			this.userList.splice(this.userList.indexOf(tag), 1);
		},
		updateTag(data) {
			this.orgIds = [];
			for (const org of data) {
				this.orgIds.push(org.id);
			}
			this.orgList = data;
		},
		updateStaffTag(data) {
			this.userList = data;
		},
		handleExceed(files, fileList) {
			if (files.length > 5 || fileList.length === 5 || files.length + fileList.length > 5) {
				this.$message({
					message: '上传文件数量不能超过五个',
					type: 'warning'
				});
			}
		},
		// 文件上传
		handleChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 100;

			if (!isLt50M) {
				this.$alert('大小不得超过100M');
				return false;
			}
			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios.post('/v1/common/upload/file', formData, { headers: { 'Content-Type': type } }).then((res) => {
				this.uploadVal.push({
					name: res.fileName,
					size: file.size,
					url: res.httpUrl,
					bucketName: res.bucketName,
					key: res.key
				});
				this.contrachLoading = false;
			});
		},
		handleRemove(file) {
			const _list = [];

			for (const key in this.uploadVal) {
				if (this.uploadVal[key].name != file.name) {
					_list.push({
						name: this.uploadVal[key].name,
						size: this.uploadVal[key].size,
						url: this.uploadVal[key].url,
						bucketName: this.uploadVal[key].bucketName,
						key: this.uploadVal[key].key
					});
				}
			}
			this.uploadVal = _list;
		},
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		downLoadFile(url) {
			window.open(url);
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible2 = true;
		},
		isImageFileName,
		_returnTitle() {
			if (this.$route.query.type == 1) {
				if (this.$route.query.id && !this.$route.query.copy) {
					return '编辑培训计划';
				}
				return '新增培训计划';
			}
			const _name = this.form.trainingCourse;

			return `${_name}详情`;
		},
		_changeStartTime(val) {
			if (this.form.trainingEndTime && new Date(this.form.trainingEndTime).getTime() <= new Date(val).getTime()) {
				this.form.trainingStartTime = '';
				this.$message.error('培训开始时间要比培训结束时间早');
			}
		},
		_changeEndTime(val) {
			if (val && this.form.trainingStartTime && new Date(this.form.trainingStartTime).getTime() >= new Date(val).getTime()) {
				this.form.trainingEndTime = '';
				this.$message.error('培训结束时间要比培训开始时间晚');
			}
		}
	},
	mounted() {
		const now = new Date();
		const nowYear = now.getFullYear(); // 当前年
		const nowMonth = now.getMonth() + 1; // 月
		const nowDay = now.getDate();
		const nowh = now.getHours();
		const nowm = now.getMinutes();
		const nows = now.getSeconds();

		this.form.trainingPublishTime = `${nowYear}-${nowMonth}-${nowDay} ${nowh}:${nowm}:${nows}`;
		if (this.$route.query.cc) {
			this._getDetail({ id: this.id, trainingCourseId: this.trainingCourseId, readStatus: '1' });
		} else {
			this.id && this.trainingCourseId == undefined ? this._getDetail() : '';
			// this.formType == 3 ? this._getStaffDetail() : this._getDetail()
			this.trainingCourseId != undefined ? this._getStaffDetail() : '';
		}
	}
};
</script>
<style lang="scss">
.trainDetail {
	.el-upload-list {
		.el-upload-list__item {
			background-color: #f4f4f5;
			i {
				transform: translateX(-5px);
			}
		}
		.el-upload-list__item:hover {
			background-color: #f5f7fa;
		}
	}
	.warningWord {
		font-size: 12px;
		height: 20px;
		transform: translateY(2px);
		color: #606266;
	}
	// header {
	//   position: fixed;
	//   top: 30px;
	// }
}
</style>
<style lang="scss" scoped>
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.section-wrap {
		margin-bottom: 20px;
	}
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.moryeWidth {
	width: 63%;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		min-width: 120px;
	}
	.fileName {
		// display: block;
		font-size: 12px;
		display: inline-block;
		transform: translateY(-5px);
		text-align: left;
		// width: 300px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
	.media-print__hidden {
		font-size: 12px;
		display: inline-block;
		transform: translateY(-8px);
	}
}
</style>
